import { Component, OnInit } from '@angular/core';
import { QuizGatewayService } from '../services/quiz-gateway.service';
export interface Tile {
  color: string;
  forecolor: string;
  cols: number;
  rows: number;
  text: string;
  itemid: string;
  quiznew: boolean;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  tiles: Tile[] = [];

  constructor(private quizgateway: QuizGatewayService) { }

  ngOnInit(): void {    
    let col = 1;
    let row = 1;
    let i = 0;

    const colourmap = ['#FDFEFE','#F5EEF8','#EAEDED','#EBDEF0','#D7BDE2','#D5DBDB','#C39BD3','#BFC9CA','#AF7AC5','#AAB7B8','#95A5A6','#9B59B6','#884EA0','#839192','#76448A','#717D7E','#633974','#5F6A6A','#512E5F','#4D5656']
    this.quizgateway.itemSetList().then(response => {
      response.sort((a,b) => a.setid.localeCompare(b.setid)|| a.itemid.localeCompare(b.itemid));
      response.forEach(element => {     
        if (col === 2){
          this.tiles.push({
            text: element.quizname,
            itemid: element.itemid,
            cols: col,
            rows: row,
            quiznew: (this.new_quiz(element.quizdate)) ? true : false,
            forecolor: (element.setid >= 10) ? '#F2F3F4' : '#512E5F',
            color: colourmap[Number(element.setid)]});
          row += 1;
          col = 1;
        }else{
          this.tiles.push({
            text: element.quizname,
            itemid: element.itemid,
            cols: col,
            rows: row,
            quiznew: (this.new_quiz(element.quizdate)) ? true : false,
            forecolor: (element.setid >= 10) ? '#F2F3F4' : '#512E5F',
            color: colourmap[Number(element.setid)]});
          col += 1;
        }
        if(i === (colourmap.length - 1)){
          i = 0;
        }
        else{
          i += 1;
        }
      });
    });
  }

  parsed_date(){
    const today = new Date();
    const dd = Number(String(today.getDate()).padStart(2, '0'));
    const mm = Number(String(today.getMonth() + 1).padStart(2, '0'));
    const yyyy = today.getFullYear();

    return new Date(yyyy, mm, dd);
  }

  new_quiz(quizdate){
    if (this.parsed_date().getDate() - Number(quizdate) < 31){
      return true;
    }
    return false;
  }
}
