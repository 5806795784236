import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {MaterialModule} from './material-module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';
import Amplify from 'aws-amplify';
import { QuizViewComponent } from './quiz-view/quiz-view.component';
import { FormsModule } from '@angular/forms';

Amplify.configure({
  API: {
    endpoints: [
        {
          name: 'er4QuizWeb',
          endpoint: 'https://kjkrhtun65.execute-api.ap-southeast-2.amazonaws.com/prod',
        }
      ]
  }
});
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    QuizViewComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    BrowserAnimationsModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
