<section class="section">
  <div class="container">
    <div class="columns is-mobile is-centered">
      <div class="column is-full">
        <h2 class="title">Quizzes
        </h2>
        <div class="menu_content">
          <mat-grid-list
            cols="3"
            rowHeight="60px"
            [gutterSize]="'10px'"
            color="primary"
          >
            <mat-grid-tile
              *ngFor="let tile of tiles"
              [colspan]="1"
              [rowspan]="1"
              [style.background]="tile.color"
              [style.color]="tile.forecolor"
              [routerLink]="['/quizdeck', tile.itemid, tile.text]"
            >
              <!-- <div class="tagnew">
                <label *ngIf="tile.quiznew">new</label>
              </div> -->
              {{ tile.text }}
            </mat-grid-tile>
          </mat-grid-list>
        </div>
      </div>
    </div>
  </div>
</section>
