import { Component, OnInit } from '@angular/core';
import { QuizGatewayService } from 'src/app/services/quiz-gateway.service';
import { Option, Question, Quiz, QuizConfig } from '../types/index';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-quiz-view',
  templateUrl: './quiz-view.component.html',
  styleUrls: ['./quiz-view.component.scss']
})
export class QuizViewComponent implements OnInit {
  diagnosticQuestion: Question;
  quizName: string;
  quizes: any[];
  quiz: Quiz;
  mode = 'quiz';
  timer: any = undefined;
  startTime: Date;
  endTime: Date;
  ellapsedTime = '00:00';
  duration = '';
  posted = false;
  config: QuizConfig = {
    allowBack: true,
    allowReview: true,
    autoMove: false,  // if true, it will move to next question automatically when answered.
    duration: 300,  // indicates the time (in secs) in which quiz needs to be completed. 0 means unlimited.
    pageSize: 1,
    requiredAll: false,  // indicates if you must answer all the questions before submitting.
    richText: false,
    shuffleQuestions: false,
    shuffleOptions: false,
    showClock: false,
    showPager: true,
    theme: 'none'
  };

  pager = {
    index: 0,
    size: 1,
    count: 1
  };

  constructor(
    private quizGatewayService: QuizGatewayService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.loadQuiz(this.quizName);
  }

  loadQuiz(quizName: string) {
    this.route.params.subscribe(params => {
      const itemid = {itemid: params['itemid']};
      this.quizGatewayService.getItem(itemid).then(response => {            
        this.quiz = new Quiz(response, null);
        this.pager.count = this.quiz.questions.length;
        if (this.pager.count === 20){
          this.config.duration = 600;
        }
        this.startTime = new Date();
        this.ellapsedTime = '00:00';
        this.timer = setInterval(() => { this.tick(); }, 1000);
        this.duration = this.parseTime(this.config.duration);
      });
    });
    this.mode = 'quiz';
  }

  tick() {    
    const now = new Date();
    const diff = (now.getTime() - this.startTime.getTime()) / 1000;
    if (diff >= this.config.duration) {      
      this.timer = undefined;
      if(!this.posted){        
        this.onSubmit();
      }
    }
    this.ellapsedTime = this.parseTime(diff);
  }

  parseTime(totalSeconds: number) {
    let mins: string | number = Math.floor(totalSeconds / 60);
    let secs: string | number = Math.round(totalSeconds % 60);
    mins = (mins < 10 ? '0' : '') + mins;
    secs = (secs < 10 ? '0' : '') + secs;
    return `${mins}:${secs}`;
  }

  onSelect(question: Question, option: Option) {
    if (question.questionTypeId === 1) {
      question.options.forEach((x) => { if (x.id !== option.id)
        {
          x.selected = false;
        }else{
          x.selected = true;
        }
      });
    }
    this.diagnosticQuestion= question;
    if (this.config.autoMove) {
      this.goTo(this.pager.index + 1);
    }
  }

  isAnswered(question: Question) {
    return question.options.find(x => x.selected) ? 'Answered' : 'Not Answered';
  };

  isCorrect(question: Question) {
    return question.options.every(x => x.selected === x.isAnswer) ? 'correct' : 'wrong';
  };

  goTo(index: number) {
    if (index >= 0 && index < this.pager.count) {
      this.pager.index = index;
      this.mode = 'quiz';
    }
    else{

    }
  }

  get filteredQuestions() {
    return (this.quiz.questions) ?
      this.quiz.questions.slice(this.pager.index, this.pager.index + this.pager.size) : [];
  }

  onSubmit() {
    this.timer = undefined;
    this.posted = true;
    let answers = [];
    this.quiz.questions.forEach(x => answers.push({ 'quizId': this.quiz.itemid, 'questionId': x.id, 'answered': x.answered }));
    this.quizGatewayService.submitItem(this.quiz)
    this.mode = 'result';
  }
  //get diagnostic() { return JSON.stringify(this.diagnosticQuestion); }
}
