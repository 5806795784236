import { Option } from './option';

export class Question {
    id: number;
    name: string;
    questionTypeId: number;
    options: Option[];
    answered: boolean;
    checklink: string;
    explanation: string;
    
    constructor(data: any) {
      if (data){
        data = data || {};
        this.id = data.id;
        this.name = data.name;
        this.questionTypeId = data.questionTypeId;
        this.options = [];

        if(data.checklink){          
          this.checklink = data.checklink;
        }
        else{          
          this.checklink == null;
        }
        if(data.explanation){          
          this.explanation = data.explanation;
        }
        else{          
          this.explanation == null;
        }
        data.options.forEach(o => {
            this.options.push(new Option(o));
        });
      }
      else{
        this.options = [];
        for ( let i = 0; i < 4; i++){
          this.options.push(new Option(null));
        }
      }
    }
}
