import { QuizConfig } from './quiz-config';
import { Question } from './question';

export class Quiz {
    quizdate: string;
    itemid: number;
    quizname: string;
    setid: string;
    config: QuizConfig;
    questions: Question[];
    quiznew: boolean; 

    constructor(data: any, questioncount: number) {
      if (data) {
        this.itemid = data.itemid;
        this.quizname = data.quizname;        
        this.setid = data.setid;
        this.config = new QuizConfig(data.config);
        this.questions = [];
        data.questions.forEach(q => {
                this.questions.push(new Question(q));
            });
        }
        else{
          this.quizdate = this.parsed_date().toString();
          this.questions = [];

          for ( let i = 0; i < questioncount; i++){
            this.questions.push(new Question(null));
          }
        }
    }

    parsed_date(){
      const today = new Date();
      const dd = Number(String(today.getDate()).padStart(2, '0'));
      const mm = Number(String(today.getMonth() + 1).padStart(2, '0'));
      const yyyy = today.getFullYear();

      return new Date(yyyy, mm, dd).getTime();
    }
}
